


























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
  components: {
    MaterialSelect
  }
})
export default class AddBasic extends Vue {
    @Prop() value: any
    @Prop({ default: () => ({}) }) lists: any
    addVideo = false
    form = {}
    fileList = []
    goodsType = [
      {
        type: 1,
        label: '实物商品',
        desc: '物流发货'
      },
      /* {
            type: 2,
            label: '虚拟商品',
            desc: '虚拟发货'
        } */
      {
        type: 3,
        label: '服务商品',
        desc: '门店服务'
      }
    ]

    @Watch('value.video', { immediate: true })
    videoChange (val: string) {
      if (val) {
        this.addVideo = true
      }
    }

    get categoryList () {
      return this.lists.category_list || []
    }

    get brandList () {
      return this.lists.brand_list || []
    }

    get unitList () {
      return this.lists.unit_list || []
    }

    get supplierList () {
      return this.lists.supplier_list || []
    }

    created () {}
}
