














































import { Component, Prop, Vue ,Watch } from 'vue-property-decorator'
import {apigoodsServiceGuaranteelists, GoodsDeliveryTemplate} from '@/api/goods'
@Component
export default class AddSales extends Vue {
    @Prop() value: any
    @Prop() isGatherGoods: any
    goodsServiceGuaranteelists: any = []
    @Watch('value',{immediate:true})
    valueChange(val:any){
      apigoodsServiceGuaranteelists({
        sid: this.value.sid
      }).then(res => {
        this.goodsServiceGuaranteelists = res
      })
    }
    created () {
      /*apigoodsServiceGuaranteelists().then(res => {
        this.goodsServiceGuaranteelists = res
      })*/
    }
}
